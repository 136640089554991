import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import reactGA from "react-ga";

reactGA.initialize("G-KMVG3SSFZ2");

export default function App() {
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 3,
        }}
      >
        <Typography variant="h1" component="div" gutterBottom>
          The best manga site.
        </Typography>
        <Typography variant="h3" gutterBottom>
          Every. Single. Comic. In one place.
        </Typography>
        <Typography variant="h4" gutterBottom component="div">
          Coming soon.
        </Typography>
      </Box>
    </Container>
  );
}
